<template>
	<Card class="payment">
		<template #content>
			<div
				v-if="paymentData.payment_status === 'paid'"
				class="payment__successful">
				<div class="payment__box-icon">
					<span class="payment__icon pi pi-check"></span>
				</div>

				<div class="payment__successful-desc">
					<h3 class="text-2xl">Оплачено {{ useFormatCurrency(Number(paymentData.payment.amount)) }}</h3>

					<p>Дата оплаты {{ useFormatDate(paymentData.payment.date) }}</p>
				</div>
			</div>

			<div
				v-else
				class="payment__wrapper">
				<p class="text-xl line-height-3">Оплата штрафа по постановлению №{{ paymentData.resolution_number }} от {{ useFormatDate(paymentData.date) }}</p>

				<div
					v-if="paymentData.discount"
					class="payment__desc">
					<div class="payment__desc-price">
						<div class="payment__price-wrapper">
							<div class="payment__price-discount text-3xl font-medium">{{ useFormatCurrency(Number(paymentData.discount_amount)) }}</div>

							<div class="payment__price text-3xl font-medium">{{ useFormatCurrency(Number(paymentData.amount)) }}</div>
						</div>

						<Tag
							class="payment__discount text-xs"
							:value="`-${paymentData.discount}%`"
							severity="danger" />
					</div>

					<p class="payment__discount-desc">Скидка действует до {{ useFormatDate(paymentData.discount_end_date) }}</p>
				</div>

				<div
					v-else
					class="payment__desc">
					<div class="payment__desc-price">
						<div class="payment__price-wrapper">
							<div class="payment__price-discount text-3xl font-medium">{{ useFormatCurrency(Number(paymentData.amount)) }}</div>
						</div>
					</div>
				</div>

				<PaymentForm
					v-if="paymentData.can_pay"
					:elements="[id]"
					:type="type"
					:back-url="path"
					:sum="Number(getFinalSum)" />

				<NuxtLink
					class=""
					:to="'https://www.rexrent.ru/usloviya/'"
					external
					target="_blank">
					<Button
						link
						label="Публичная оферта" />
				</NuxtLink>
			</div>
		</template>
	</Card>
</template>

<script setup lang="ts">
	import type { IPayment } from "@/assets/ts/interfaces/payment/IPayment";
	import { Payment as PaymentClass } from "@/assets/ts/models/payment/Payment";
	import { PaymentRepository } from "@/assets/ts/models/payment/PaymentRepository";
	import { useFormatCurrency } from "~/composables/useFormatCurrency";
	import { LayoutTypes } from "@/assets/ts/enums/detail-with-payment/LayoutTypes";

	const { path } = useRoute();

	const props = defineProps<{
		id: string;
		data: IPayment;
		type: LayoutTypes;
	}>();

	const payment = new PaymentClass({
		amount: props.data.amount,
		date: props.data.date,
		discount: props.data.discount,
		discount_amount: props.data.discount_amount,
		discount_end_date: props.data.discount_end_date,
		link_to_payment: props.data.link_to_payment,
		resolution_number: props.data.resolution_number,
		payment_status: props.data.payment_status,
		payment_fact: props.data.payment_fact,
		can_pay: props.data.can_pay,
		payment: {
			date: props.data.payment.date,
			amount: props.data.payment.amount,
		},
	});

	const paymentService = new PaymentRepository(payment);
	const paymentData = paymentService.getPaymentData;

	const getFinalSum = paymentData.discount ? paymentData.discount_amount : paymentData.amount;
</script>

<style scoped lang="scss">
	@import "payment";
</style>
